<template>
  <div
    class="field"
    :class="{
      'field--inline': inline,
      'field--disabled': disabled,
      'field--error': errorToDisplay,
      'field--optional': optional,
    }"
  >
    <label v-if="label" class="field__label">
      <span>{{ label }}</span>
      <span v-if="isRequired" class="text-xs text-danger-500">*</span>
    </label>
    <div class="field__input">
      <slot />
    </div>
    <p v-if="errorToDisplay" class="field__error">
      {{ errorToDisplay }}
    </p>
    <p v-if="desc" class="field__desc">
      {{ desc }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    label: { type: String, default: null },
    desc: { type: String, default: null },
    error: { type: Array, default: null },
    inline: Boolean,
    disabled: Boolean,
    optional: Boolean,
    showError: {
      type: Boolean,
      default: true,
    },
    rules: {},
  },
  computed: {
    isRequired() {
      return this.rules?.includes("required");
    },

    errorToDisplay() {
      if (this.showError) {
        if (this.error) {
          return this.error;
        } else if (this.$parent.errors?.[0]) {
          return this.$parent.errors[0];
        }
      }
      return null;
    },
  },
};
</script>

<style>
.field__label {
  @apply block text-sm text-gray-500 mb-1;
}
.field__desc {
  @apply text-sm text-gray-500 mt-1;
}
.field__error {
  @apply text-sm text-danger-500 mt-1;
}

.field--inline {
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-template-rows: min-content min-content min-content;

  .label {
    grid-column: 1 / 2;
  }
  .field__input {
    grid-column: 2 / 3;
  }

  .field__desc {
    grid-row: 2 / 3;
    grid-column: 1 / 3;
    margin: 0;
  }

  .field__error {
    grid-row: 3 / 4;
    grid-column: 1 / 3;
  }
}

.field--optional {
  .field__label {
    &:after {
      content: " — Optional";
      font-style: italic;
      font-size: 11px;
    }
  }
}
</style>
